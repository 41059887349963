<template>

  <div class="relative">
    <div class="mt-4 h-10 flex justify-between border-b-4 border-gray-50 relative">
      <!--菜单-->
      <div class="ml-6 tabs relative">
        <a v-for="(item,index) in tabList"
           ref="tab"
           :class="tabStyle.index === index ? 'tab tab-active':'tab'"
           @click="tabClick(item.url)"
           @mouseenter="tabMouseenter(index)"
           @mouseleave="tabMouseleave()">
          {{ item.name }}
        </a>
        <div :style="'left: '+tabStyle.left+'px; width: '+tabStyle.width+'px; transition: all 0.3s ease 0s;'"
             class="bottom-[-4px] h-[4px] absolute bg-blue-400 transition"></div>
      </div>
      <!--搜索-->
      <div class="mr-6 flex h-8 max-sm:hidden">
        <div class="flex bg-gray-100 rounded-md items-center">
          <i class="ri-search-line ml-1 text-center text-lg text-gray-400"/>
          <input class="input bg-inherit px-1 rounded-md input-sm w-full max-w-xs"
                 placeholder="search"
                 v-model="search"
                 type="text"/>
        </div>
        <button class="btn btn-primary btn-sm h-8 ml-2" @click="searchBtn">搜索</button>
      </div>
    </div>

    <!--路由-->
    <div class="relative b-scrollbar max-md:min-h-[calc(100vh-24.75rem)]" ref="routeRef">

      <div class="w-full h-full absolute bottom-0 z-50 px-4 pt-2" v-if="this.$store.state.loadingShow">
        <div class="bg-slate-50 relative w-full h-full rounded flex items-center justify-center">
          <p class="text-lg text-gray-400">Loading...</p>
        </div>
      </div>

      <router-view class="w-full p-6" v-show="!this.$store.state.loadingShow"/>
    </div>


    <!--移动端底部-->
    <div class="md:hidden w-full text-center py-4">
      <div class="divider my-0 px-4"/>
      <p class="text-gray-400 mb-2">{{ $store.state.webInfo.copyright }}</p>
      <p class="text-xs tracking-tight">
        <a class="text-gray-300" target="_blank" href="https://beian.miit.gov.cn/">{{ $store.state.webInfo.icp }}</a>
      </p>
      <p class="text-xs tracking-tight" v-if="this.$store.state.webInfo.gongan">
        <a class="text-gray-300 flex justify-center" target="_blank"
           :href="'https://beian.mps.gov.cn/#/query/webSearch?code='+this.$store.state.webInfo.gongan">
          <img src="../../assets/img/gongan.png" class="w-4	h-4" alt=""/>
          {{ $store.state.webInfo.gongan }}
        </a>
      </p>
    </div>

  </div>

</template>

<script>
import {get} from "@/utils/request";

export default {
  name: "URight",
  data() {
    return {
      tabStyle: {
        left: 0,
        width: 0,
        index: undefined
      },
      tabLeft: 0,
      tabList: [
        {name: '首页', url: ''}

      ],
      search: ''
    }
  },
  mounted() {
    this.getTabList();
  },
  methods: {
    //鼠标移入tab
    tabMouseenter(index) {
      this.tabStyle.left = this.$refs.tab[index].offsetLeft;
      this.tabStyle.width = this.$refs.tab[index].clientWidth - 18;
    },
    //鼠标移出tab
    tabMouseleave() {
      let path = window.location.pathname;
      this.tabStyle = {
        left: 0,
        width: 0,
        index: undefined
      }
      this.tabList.forEach((item, index) => {
        if ('/' + item.url === path) {
          this.tabStyle = {
            left: this.$refs.tab[index].offsetLeft,
            width: this.$refs.tab[index].clientWidth - 18,
            index: index
          }
        }
      });
    },
    //点解tab
    tabClick(url) {
      this.$router['push']('/' + url);
    },
    //点击搜索
    searchBtn() {
      if (this.search) {
        this.$router['push']('/search/' + this.search);
      }
    },
    getTabList() {
      get('/menu').then(res => {
        res.data.forEach(d => {
          this.tabList.push({
            name: d.name,
            url: 'type/' + d.url
          });
        });
        setTimeout(() => {
          this.tabMouseleave();
        }, 100)
      })
    }
  },
  watch: {
    // 监听路由对象中的变化
    '$route': {
      handler() {
        this.tabMouseleave();
        this.$refs.routeRef.scrollTop = 0;
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<style scoped>
::v-deep(.tab) {
  color: #4b5563;
  padding-left: 0;
}

::v-deep(.tab-active) {
  color: #60a5fa;
}

@media screen and (min-width: 1280px) {
  .b-scrollbar {
    overflow-y: scroll;
    height: calc(100% - 4rem);
  }
}

@media screen and (min-width: 768px) {
  .b-scrollbar {
    overflow-y: scroll;
    height: calc(100% - 3.5rem);
  }
}

</style>
